est-faq {
    display: block;
    overflow: hidden;
}

.est-faq {
    margin-top: 20px;
}

.est-faq__container {
    padding: 10px 0px;
}

.est-faq__container__item {
    padding: 5px 0px;
}

.est-faq__container__item--question {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-weight: bold;
    padding: 15px;
    cursor: pointer;
    border-radius: 40px;
    transition: margin 0.2s ease-out;
    z-index: 1000;
}

.est-faq__container__item--answer {
    position: relative;
    overflow: hidden;
    max-height: 0px;
    padding: 0 20px;
    transition: all 0.2s ease-out;
    background: rgba(255, 255, 255, 0.15);
    color: white;
    position: relative;
    top: -32px;
    border-radius: 28px;
    z-index: 900;
}

.est-faq__container__item--question::after {
    content: "+";
    font-size: 1.5rem;
    color: #777777;
    position: absolute;
    right: 20px;
    top: 10px;
    color: white;
}

.est-faq__container__item--answer--active {
    position: relative;
    top: -50px;
    padding-top: 45px;
    margin-bottom: -40px;
}

.est-faq__container__item--answer--active--first {
    position: relative;
    top: -50px;
    padding-top: 45px;
    margin-bottom: -40px;
}

.est-faq__container__item--question--active:after {
    font-size: 2rem;
    content: "-";
    position: absolute;
    right: 20px;
    top: 5px;
    color: white;
}

@media (max-width: 600px) {
    .est-faq {
        font-size: 0.75rem;
    }

    .est-faq__container__item--answer--active{
        padding-top: 50px;
        top: -65px;
        margin-bottom: -45px;
        margin-top: 20px;
    }
}

@media (max-width: 450px) {
    .est-faq {
        font-size: 0.70rem;
    }

    .est-faq__container__item--question::after {
        content: "";
    }

    .est-faq__container__item--question--active:after {
        content: "";
    }

    .est-faq__container__item--answer--active, .est-faq__container__item--answer--active--first {
        padding-top: 50px;
        top: -65px;
        margin-bottom: -45px;
        margin-top: 20px;
    }
}
