est-schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    --est-schedule-width: 70vw; 
}

.est-schedule__days {
    width: var(--est-schedule-width);
    display: grid;
    margin-bottom: 20px;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 7 - 1rem), 1fr));
}

.est-schedule__days__btn {
    width: 100%;
    padding: 15px;
    border-radius: 50px;
    font-size: 1.75rem;
    font-family: var(--secondary-font);
    font-weight: 900;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    color: white;
    border: 0px solid white;
    text-align: center;
}

.est-schedule__days__btn--active  {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    box-shadow: 0 0 10px 2px #eaeaea;
    border: 1px solid white;
}

.est-schedule__events-container {
    min-height: 50vh;
    width: var(--est-schedule-width);
    padding: 20px;
}

.est-schedule__events {
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    z-index: 900;
}

.est-schedule__events--hidden {
    display: none;
}

.est-schedule__events__item {
    padding: 10px;
    font-size: 1.25rem;
}

.est-schedule__events__item--time {
    justify-self: start;
}

.est-schedule__events__item--event {
    /* width: 50%; */
}

.est-schedule__select {
    display: none;
}

@media (mid-width: 701px) {}

@media (max-width: 700px) {
    .est-schedule__days {
        display: none;
    }

    .est-schedule__select {
        display: block;
        align-self: center;
    }
}
