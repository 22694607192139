@font-face {
    font-family: "Bavro-Regular";
    src: url("./assets/fonts/Bavro-Regular.eot");
    src: url("./assets/fonts/Bavro-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/Bavro-Regular.svg#Bavro-Regular") format("svg"),
        url("./assets/fonts/Bavro-Regular.ttf") format("truetype"),
        url("./assets/fonts/Bavro-Regular.woff") format("woff"),
        url("./assets/fonts/Bavro-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@import "./src/styles/est-nav.css";
@import "./src/styles/est-register-btn.css";
@import "./src/styles/est-schedule.css";
@import "./src/styles/est-select.css";
@import "./src/styles/est-faq.css";
@import "./src/styles/est-footer.css";

:root {
    /* colors */
    --primary-color-1: white;
    --primary-color-2: white;

    /* fonts */
    --primary-font: "Bavro-Regular", "sans-serif";
    --secondary-font: "Quicksand", "sans-serif";

    /* gutters */
    --content-padding: var(--nav-height) 0px;
    --content-margin: 0 20%;

    /* nav */
    --nav-height: 60px;
}

body {
    background: linear-gradient(180deg, #658aae 0%, #8bc2b9 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0px;
    padding: 0px;
    font-family: var(--secondary-font);
    overflow-x: hidden;
}

h1 {
    font-family: var(--secondary-font);
    margin: 0px;
}

a {
    color: white;
}

a:visited {
    color: white;
}

#mlh-logo-container {
    position: absolute;
    top: 0px;
    left: 30px;
    animation: 1.5s cubic-bezier(0.22, 1, 0.36, 1) slidein--top;
}

#mlh-logo-container img {
    height: 120px;
    width: 70px;
    padding: 0px;
}

est-nav {
    animation: 1s cubic-bezier(0.22, 1, 0.36, 1) slidein--top;
}

.splash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
    max-width: 100vw;
    min-height: 90vh;
    overflow: hidden;
}

.banner {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: 2vh;
    background-color: white;
}

.splash__item {
}

.splash__item > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.splash__illo {
    height: 650px;
    animation: 1s cubic-bezier(0.22, 1, 0.36, 1) slidein;
    padding: 20px;
}

.splash__main {
    display: flex;
    flex-direction: column;
}

.title,
.quote {
    font-family: var(--primary-font);
    font-size: 3.5rem;
}

.registration {
    font-size: 1.5rem;
    font-family: var(--secondary-font);
    padding-bottom: 15px;
}

.title {
    font-size: 7rem;
    font-weight: bold;
}

.splash-container .title {
    animation: 1s cubic-bezier(0.22, 1, 0.36, 1) slidein, 1.5s ease-out fadein,
        1s ease-out 1s glow forwards;
}

.splash-container .quote {
    animation: 1.2s cubic-bezier(0.22, 1, 0.36, 1) slidein, 1.5s ease-out fadein,
        1s ease-out 1.2s glow forwards;
}

.date {
    font-size: 2.5rem;
    font-family: var(--secondary-font);
    color: white;
    padding: 30px 0px;
    animation: 1.4s cubic-bezier(0.22, 1, 0.36, 1) slidein, 1.5s ease-out fadein;
}

.header {
    font-size: 3.5rem;
    font-family: var(--primary-font);
    text-align: center;
    text-shadow: 0px 0px 30px white;
}

est-register-btn {
    animation: 1.6s cubic-bezier(0.22, 1, 0.36, 1) slidein, 1.5s ease-out fadein,
        1.5s 1.8s pop;
}

.about-container-sentinel {
    overflow: hidden;
    padding: 0% 15%;
    display: flex;
    align-items: center;
    height: 100vh;
}

.about-container {
    width: calc(200vw);
    /* height: calc(100vh - 180px); */
    padding-top: 120px;
    display: flex;
}

.about-header {
    font-size: 2.5rem;
}

.about-col-1 {
    width: 30vw;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
}

img {
    height: 300px;
    width: auto;
    padding: 30px;
}

.game-cube {
    height: 350px;
}

.about-col-2 {
    width: 150vw;
    padding-left: 5%;
}

.about-col-2__row-1 {
    display: flex;
    height: 60%;
}

.about-col-2__row-1__item {
    padding-right: 15%;
    display: flex;
    flex-direction: column;
    width: 33%;
}

.about-col-2__row-1__item:last-child {
    padding-left: 0px;
    padding-right: 10%;
}

.about-col-2__row-2 {
    background: url("./assets/illos/about-long-glow.png");
    background-size: contain;
    background-repeat: repeat-x;
    height: 300px;
    width: 200vw;
}

.full-container {
    padding-top: 120px;
}

.scroll-cue {
    position: absolute;
    bottom: 100px;
    opacity: 0;
}

.scroll-cue-anim {
    animation: scroll-cue 2.5s ease-out forwards;
}

.new-experience-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100vh;
    height: 100vh;
    position: absolute;
    width: 100vw;
    /* background: url("./assets/illos/starwars.png"); */
    background-size: contain;
    background-repeat: no-repeat;
}

.new-experience {
    text-align: center;
    font-size: 4.75rem;
    font-weight: bold;
    font-family: var(--primary-font);
    z-index: 900;
    position: relative;
}

#starwars {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
}

#starwars img {
    height: 100vh;
    width: auto;
}

/* i'm bad at names ;( haha nah man don't worry I like all your names */
.center-row {
    display: flex;
    overflow: hidden;
    padding: 0px 15%;
    flex-wrap: wrap;
}

.center-row > div {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
}

.not-so-full-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.tracks__track {
    margin: 20px 40px 0px 40px;
}

.tracks__track h1,
.tracks__track p {
    text-align: center;
}

.letter-title {
    font-size: 3.5rem;
    font-family: var(--primary-font);
    text-shadow: 0px 0px 30px white;
    text-align: center;
}

.letter-container {
    padding: 0px 15%;
}

.letter {
    padding: 30px 10%;
}

.letter p {
    font-size: 1.25rem;
    padding-bottom: 40px;
}

.letter-imgs {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.letter-imgs img {
    width: 70%;
    height: auto;
}

.signature {
    text-align: right;
    font-size: 2rem;
}

.faq-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.sponsors {
    display: grid;
    /* grid-template-rows: repeat(4, 100%); */
    justify-content: center;
}

.tier {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.tier a {
    text-decoration: none;
}

.tier-1 img {
    height: 75px;
}

.tier-2 img {
    height: 67px;
}

.tier-3 img {
    height: 45px;
}

.tier-4 img {
    height: 35px;
}

.tier-5 img {
    height: 25px;
}

.tier img {
    transition: transform 0.5s;
}

.tier img:hover {
    transform: scale(1.1, 1.1);
}

#letter-icon {
    height: 1.5rem;
    width: 1.5rem;
}

#bulb,
#whale {
    position: absolute;
    height: auto;
}

#bulb {
    top: 30vh;
    left: 0vw;
    width: 200px;
}

#whale {
    top: 0vh;
    right: -5vw;
    width: 320px;
    transform: rotate(15deg);
}

#bottle {
    height: 85vh;
    position: relative;
    z-index: 700;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #658aae;
    padding: 20px;
    margin: 20px auto;
    border-radius: 50px;
    width: 40%; /* Could be more or less, depending on screen size */
    margin-top: 60px;
}

#mc_embed_signup {
    background: #658aae;
    clear: left;
    font: 14px var(--secondary-font);
    color: white;
    padding: 5px 15px 5px 15px;
    padding-right: 50px;
}

.mc-field-group input,
.mc-field-group select {
    border-radius: 10px !important;
    border: white solid 1px;
    color: white;
    background-color: white;
    margin-top: 10px;
    font-family: var(--secondary-font);
    background-color: rgb(255, 255, 255, 0.2);
}

.mc-field-group {
    margin: 10px;
    width: 100%;
}

.mc-field-group option {
    color: black;
}

.expand input {
    padding: 12px;
    outline: none;
    border: white solid 1px;
    color: white;
    width: 100%;
}

input:-internal-autofill-selected {
    background-color: rgb(255, 255, 255, 0.2);
}

select:focus {
    background-color: rgb(255, 255, 255, 0.2);
}

.input-group {
    background-color: rgb(255, 255, 255, 0.2);
    padding: 12px;
    border: white solid 1px;
    border-radius: 10px !important;
    margin-top: 20px;
    padding-top: 0px;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.mc-field-group select {
    padding: 12px;
    width: 105%;
}

.mc-field-group label {
    margin-right: 5px;
    margin-bottom: 5px;
}

.mc-field-group ul {
    list-style-type: none;
    padding-left: 10px;
    margin-top: 5px;
}

#mc-embedded-subscribe {
    width: 105%;
    padding: 15px;
    border-radius: 50px;
    font-size: 1.3rem;
    font-family: var(--secondary-font);
    font-weight: 900;
    background: rgba(255, 255, 255, 0.3);
    border: 0px solid white;
    box-shadow: 0 0 10px 2px #eaeaea;
    cursor: pointer;
    transition: all 0.2s ease-out, filter 0.2s ease-in;
    color: white;
    margin-top: 15px;
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-right: 20px;
    /* margin-top: 5px; */
}

.close-div {
    z-index: 100;
    /* padding-bottom: 20px; */
}

.close:hover,
.close:focus {
    color: rgb(255, 255, 255, 0.7);
    text-decoration: none;
    cursor: pointer;
}

.clear input {
    font-family: "Quicksand", sans-serif;
    border-radius: 15px;
    padding: 10px;
    border-color: white;
    background-color: white;
    cursor: pointer;
}

est-footer {
    display: block;
    z-index: 1000;
    position: relative;
}

@media (min-width: 901px) {
}

@media (max-width: 900px) {
    .splash__illo {
        height: 600px;
    }

    .about-container-sentinel {
        height: max-content;
    }

    .about-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 0px;
    }

    .about-col-1 {
        width: 100%;
    }

    .about-col-2__row-1 {
        display: flex;
        flex-direction: column;
        height: 80%;
        padding-right: 55%;
        font-size: 1rem;
    }

    .about-col-2__row-1__item {
        padding: 30px 0px;
        width: 100%;
    }

    .about-col-2__row-1__item:last-child {
        padding-right: 0px;
    }

    .about-col-2__row-2 {
        height: 120px;
        margin-left: -20%;
    }

    .full-container {
        height: max-content;
        padding-top: 0px;
    }

    .center-row > div {
        width: 100%;
    }

    #whale {
        display: none;
    }

    #bottle-roll {
        display: none;
    }

    .faq-img-container {
        position: relative;
        height: max-content;
    }

    #bulb {
        position: initial;
        height: 150px;
        width: auto;
    }

    .letter-imgs {
        display: none;
    }

    .modal-content {
        margin: 20px auto;
        width: 70%;
    }
}

@media (max-width: 600px) {
    #mlh-logo-container {
        display: none;
    }

    .splash__illo {
        height: 300px;
    }

    .title {
        font-size: 5rem;
    }

    .quote {
        font-size: 3rem;
    }

    .date {
        font-size: 2rem;
    }

    .header {
        font-size: 3.25rem;
    }

    img {
        height: 300px;
        width: auto;
    }

    .game-cube {
        height: 300px;
        width: auto;
    }

    .new-experience {
        font-size: 3rem;
    }

    .letter-title {
        font-size: 3.25rem;
    }

    .letter p {
        font-size: 1rem;
    }

    .signature {
        font-size: 1.25rem;
    }
}

@media (max-width: 450px) {
    body {
        --content-margin: 0 10%;
    }

    .splash__illo {
        height: 300px;
    }

    .title {
        font-size: 3rem;
    }

    .quote {
        font-size: 2rem;
        text-align: center;
    }

    .date {
        font-size: 1.5rem;
    }

    .header {
        font-size: 2.5rem;
    }

    h1 {
        font-size: 1.25rem;
    }

    img {
        height: 200px;
        width: auto;
    }

    .game-cube {
        height: 200px;
        width: auto;
    }

    .new-experience {
        font-size: 2.5rem;
    }

    .letter-container {
        padding: 5%;
    }

    .letter-title {
        font-size: 2.5rem;
    }

    .letter p {
        font-size: 1rem;
    }

    .new-experience-container {
        position: relative;
    }

    .center-row {
        padding: 5%;
    }

    .tier img {
        height: 30px !important;
    }

    #fb {
        height: 20px !important;
    }

    #ncr {
        height: 50px !important;
    }

    #create-x, #ibm {
        height: 55px !important;
    }

    #gm {
        height: 10px !important;
    }

    .sponsors {
        display: flex;
        flex-direction: column;
    }

    .tier {
        display: flex;
        flex-direction: column;
    }

    .tier img {
        padding: 20px;
    }
}

@keyframes scroll-cue {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-50px);
    }
    50% {
        transform: translateY(0px);
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes glow {
    0% {
        text-shadow: 0px 0px 0px white;
    }

    100% {
        text-shadow: 0px 0px 30px white;
    }
}

@keyframes slidein {
    from {
        transform: translateY(200px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes slidein--top {
    from {
        transform: translateY(-200px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pop {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}
