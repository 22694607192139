est-nav {
    position: fixed;
    width: 100%;
    z-index: 2000;

    --nav-background: inherit;
    --nav-font-family: var(--secondary-font);
    --nav-font-size: 1.2rem;
    --nav-font-weight: normal;
    --nav-padding-left: 5%;
    --nav-padding-right: 5%;


    /* anchor styles */
    --nav-anchor-color: var(--primary-color-1);
    --nav-anchor-highlight: var(--primary-color-1);
    --nav-anchor-visited-color: var(--primary-color-1);
    --nav-anchor-hover-color: var(--primary-color-1);
    --nav-anchor-padding: 10px;
}

.est-nav__current {
    border-bottom: 3px solid var(--nav-anchor-highlight);
    font-weight: bold;
    color: var(--nav-anchor-highlight) !important;
}

.est-nav__nav {
    height: var(--nav-height);
    background: var(--nav-background);
    font-family: var(--nav-font-family);
    font-size: var(--nav-font-size);
    font-weight: var(--nav-font-weight);

    display: flex;
	transition: background 0.3s ease-out;
}

.est-nav__list {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.est-nav__list--left {
    justify-content: flex-start;
    padding-left: var(--nav-padding-left);
}

.est-nav__list--center {
    justify-content: center;
}

.est-nav__list--right {
    justify-content: flex-end;
    padding-right: var(--nav-padding-right);
}

.est-nav__list a { 
    padding: var(--nav-anchor-padding);
    color: var(--nav-anchor-color);

    text-decoration: none;
}

.est-nav__list a:visited { 
    color: var(--nav-anchor-visited-color);
}

.est-nav__list a:hover { 
    color: var(--nav-anchor-hover-color);
}

.est-nav__ham {
  position: relative;
  z-index: 1001;
}

/* Codepen https://codepen.io/ainalem/pen/LJYRxz?editors=1100 */
.ham {
  height: 50px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #e4e4e4;
  stroke-width:5.5;
  stroke-linecap:round;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

/* end codepen */

.est-nav__hidden--mobile {}

/* TODO modify this media query */
@media (min-width: 951px) {
    
}

@media (max-width: 950px) {
    .est-nav__list--left, .est-nav__list--right {
        display: none;
    }
}

@media (min-width: 501px) {
    .est-nav__ham {
        display: none;
    }
}

@media (max-width: 500px) {
    .est-nav__current {
        border: none;
    }

    .est-nav__nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .est-nav__list {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
        z-index: 900;
        padding-top: 50px;
        padding-right: 50px;
        left: calc(100% + 30px);
        transition: transform 0.3s ease-out;

        background: #8EB5B6;
        opacity: 0.7;
    }

    .est-nav__display--mobile {
        transform: translateX(-100%);
    }
}

