.est-register-btn {
    width: 100%;
    padding: 15px;
    border-radius: 50px;
    font-size: 1.75rem;
    font-family: var(--secondary-font);
    font-weight: 900;
    background: rgba(255, 255, 255, 0.3);
    border: 0px solid white;
    box-shadow: 0 0 10px 2px #eaeaea;
    cursor: pointer;
    transition: all 0.2s ease-out,
        filter 0.2s ease-in;
    color: white;
    text-align: center;
    display: inline-block;
}

button.est-register-btn:hover {
    background: rgba(255, 255, 255, 0.5);
}

button.est-register-btn:active {
    box-shadow: none;
}

button.est-register-btn:focus {
    outline: none;
}

.est-register-btn a {
    text-decoration: none;
    color: white;
}

.est-register-btn a:visited {
    color: white;
}

#newsletter {
    width: 80%;
    /* margin-left: 20px; */
    font-size: 1rem;
    margin-top: 20px;
    padding: 5px 5px 5px 5px;
}

#btns {
    text-align: center;
}